import React from "react"
import styled from "styled-components"
import Flex from "styled-flex-component"

import {
  BodyCopy,
  StandardContainer,
  FullSliceContainer,
  HeadingThree,
} from "../../common"

const StyledFullSliceContainer = styled(FullSliceContainer)`
  background-color: ${props => props.theme.colors.pullColor};
  color: #fff;
  h3 {
    margin-bottom: 3rem;
  }
`

const StyledStandardContainer = styled(StandardContainer)``

const StyledTextSection = styled(BodyCopy)`
  position: relative;
  z-index: 10;
  width: 100%;
  margin-bottom: 2rem;
  ul {
    column-count: 1;
    column-gap: 0;
    list-style-position: outside;
  }
  h1 {
    max-width: 15em;
    margin-bottom: 1rem;
    line-height: ${props => props.theme.lineHeights.heading};
    > em {
      font-family: ${props => props.theme.fonts.medium};
      font-style: normal;
      color: ${props => props.theme.colors.pullColor};
    }
  }
  > p {
    line-height: ${props => props.theme.lineHeights.standard};
  }
  @media ${props => props.theme.breakpoints.medium} {
    width: 46%;
  }
  @media ${props => props.theme.breakpoints.large} {
    width: 40%;
  }
`

// Stat
const GridItem = ({ text_section }) => {
  return (
    <>
      <StyledTextSection
        dangerouslySetInnerHTML={{ __html: text_section.html }}
      />
    </>
  )
}

export const InformationGrid = ({ slice_title, items, id }) => {
  return (
    <StyledFullSliceContainer id={id}>
      <StyledStandardContainer>
        <HeadingThree>{slice_title.text}</HeadingThree>
        <Flex wrap justifyBetween>
          {items.map(item => (
            <GridItem {...item} />
          ))}
        </Flex>
      </StyledStandardContainer>
    </StyledFullSliceContainer>
  )
}
