import React from "react"
import styled from "styled-components"

import { BodyCopy, StandardContainer, FullSliceContainer } from "../../common"

import { Enquiry } from "../../forms"

const StyledFullSliceContainer = styled(FullSliceContainer)`
  ${BodyCopy} {
    /* max-width: ${props => props.theme.measures.medium}; */
  }
  @media ${props => props.theme.breakpoints.medium} {
  }
`

export const EnquiryForm = ({ id }) => {
  return (
    <StyledFullSliceContainer nopad id={id}>
      <StandardContainer>
        <BodyCopy>
          <Enquiry />
        </BodyCopy>
      </StandardContainer>
    </StyledFullSliceContainer>
  )
}
