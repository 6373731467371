import React from "react"
import styled from "styled-components"
import Flex from "styled-flex-component"
import AnchorLink from "react-anchor-link-smooth-scroll"

import {
  PrismicBackgroundImage,
  BodyCopy,
  FullSliceContainer,
  StandardContainer,
  CircleButtonDownLight,
} from "../../common"

const StyledFullSliceContainer = styled(FullSliceContainer)`
  position: relative;
  button {
    margin: 1rem 0 0 0;
  }
  h3 {
    font-family: ${props => props.theme.fonts.bold};
    margin-bottom: 0.5rem;
  }
  display: grid;
  grid-template-rows: 6rem auto;
  grid-template-columns: 1fr;

  @media ${props => props.theme.breakpoints.medium} {
    grid-template-rows: 8rem auto 6rem;
  }
  @media ${props => props.theme.breakpoints.large} {
    grid-template-rows: 10rem auto 6rem;
  }
`

const StyledStandardContainer = styled(StandardContainer)`
  background-color: ${props => props.theme.colors.pullColor};
  width: 100%;
  display: grid;
  grid-row: 2 / span 2;
  grid-column: 1 / auto;
  background-color: transparent;
  /*  */
  > ${Flex} {
    padding: 2rem 2rem;
    border-radius: 0.3rem;
    background-color: ${props => props.theme.colors.pullColor};
    color: #fff;
    z-index: 10;
    animation-name: fadeInDown;
    animation-duration: 1s;
    animation-fill-mode: backwards;
  }
  @media ${props => props.theme.breakpoints.medium} {
    grid-template-columns: 2fr 1fr;
    > ${Flex} {
      padding: 6rem 4rem;
      grid-column: 1;
    }
  }
  @media ${props => props.theme.breakpoints.large} {
    grid-template-columns: 7fr 5fr;
  }
`

const StyledImageWrapper = styled.div`
  grid-row: 1 / span 2;
  grid-column: 1;
  position: relative;
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background: rgba(0, 42, 41, 0.56);
    display: block;
    z-index: 2;
  }
  > .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media ${props => props.theme.breakpoints.medium} {
    grid-row: 1 / span 2;
    grid-column: 1 / span 2;
  }
`

export const StandardBanner = ({ image, text, show_read_more_link }) => {
  return (
    <StyledFullSliceContainer nopad>
      <StyledImageWrapper>
        <PrismicBackgroundImage {...image} />
      </StyledImageWrapper>
      <StyledStandardContainer pad-tb nopad-tb-l>
        <Flex alignCenter>
          <BodyCopy>
            <div dangerouslySetInnerHTML={{ __html: text.html }} />
            {show_read_more_link === "yes" && (
              <AnchorLink href={`#PageIntro`} offset="100">
                <CircleButtonDownLight text="Read More" />
              </AnchorLink>
            )}
          </BodyCopy>
        </Flex>
      </StyledStandardContainer>
    </StyledFullSliceContainer>
  )
}
