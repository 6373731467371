import React from "react"
import styled from "styled-components"
import TransitionLink from "gatsby-plugin-transition-link"

import {
  BodyCopy,
  StandardContainer,
  FullSliceContainer,
  HeadingFive,
  BasicButton,
} from "../../common"

const StyledFullSliceContainer = styled(FullSliceContainer)`
  ${BodyCopy} {
    max-width: ${props => props.theme.measures.medium};
  }
  @media ${props => props.theme.breakpoints.medium} {
  }
`

export const CareerForm = ({
  form_button_text,
  button_link,
  modal_title,
  modal_text,
  slice_title,
  text,
  id,
}) => {
  return (
    <StyledFullSliceContainer id={id}>
      <StandardContainer>
        <BodyCopy>
          <HeadingFive bold>{slice_title.text}</HeadingFive>
          <div dangerouslySetInnerHTML={{ __html: text.html }} />
          <TransitionLink
            to={button_link.url}
            exit={{
              length: 0.3,
            }}
            entry={{
              length: 0.3,
              delay: 0.3,
            }}
          >
            <BasicButton margin-top>{form_button_text.text}</BasicButton>
          </TransitionLink>
        </BodyCopy>
      </StandardContainer>
    </StyledFullSliceContainer>
  )
}
