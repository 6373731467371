import React from "react"
import Recaptcha from "react-google-recaptcha"
import styled from "styled-components"

import useFormValidation from "./useFormValidation"

import { StyledTextInput, StyledTextArea, BasicButton } from "../common"

const INITIAL_STATE = {}

const FormWrapper = styled.div`
  /* max-width: ${props => props.theme.measures.medium}; */

  .form_item {
    margin-bottom: 1rem;
  }
  @media ${props => props.theme.breakpoints.medium} {
    form {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .form_item {
      margin-bottom: 1rem;
      width: 48%;
    }
    .form_item.full {
      width:100%;
    }
  }
`

export const Enquiry = ({ onClose, formOpen, category }) => {
  const { handleChange } = useFormValidation(INITIAL_STATE)

  return (
    <FormWrapper>
      <form
        name="enquiry-form"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        data-netlify-recaptcha="true"
        subject={`Grasslands - Thank you`}
        action="/thank-you/"
        method="post"
      >
        <input type="hidden" name="form-name" value="enquiry-form" />
        <p hidden>
          <label>
            Don’t fill this out:{" "}
            <StyledTextInput name="bot-field" onChange={handleChange} />
          </label>
        </p>
        <div className="form_item">
          {/* <label>First Name</label> */}
          <StyledTextInput
            placeholder="First Name"
            type="text"
            name="fname"
            required={true}
            onChange={handleChange}
          />
        </div>
        <div className="form_item">
          {/* <label>Last Name</label> */}
          <StyledTextInput
            type="text"
            placeholder="Last Name"
            name="lname"
            required={true}
            onChange={handleChange}
          />
        </div>
        <div className="form_item">
          {/* <label>Email Address</label> */}
          <StyledTextInput
            type="email"
            name="email"
            placeholder="Email Address"
            required={true}
            onChange={handleChange}
          />
        </div>
        <div className="form_item">
          {/* <label>Company Name</label> */}
          <StyledTextInput
            type="text"
            name="company"
            placeholder="Company"
            required="false"
            onChange={handleChange}
          />
        </div>
        <div className="form_item">
          {/* <label>Phone Number</label> */}
          <StyledTextInput
            type="text"
            name="phone"
            placeholder="Phone Number"
            required="false"
            onChange={handleChange}
          />
        </div>
        {/* <div className="form_item">          
          <StyledSelectList name="interest" onChange={handleChange}>
            <option value="default">Select an option</option>
            <option value="Option 1`">Option 1</option>
            <option value="Option 2">Option 2</option>
            <option value="Option 3">Option 3</option>
          </StyledSelectList>
        </div> */}
        <div className="form_item full">
          {/* <label>Enquiry</label> */}
          <StyledTextArea
            rows="6"
            name="comment"
            placeholder="Enquiry"
            required={true}
            onChange={handleChange}
          />
        </div>
        <div className="form_item captcha">
          <Recaptcha
            sitekey="6LfzmNEUAAAAAH2Ywdz00cDg3qAk2vfFxn2Fd4NX"
            onChange={handleChange}
          />
        </div>
        <div>
          <BasicButton type="submit">Submit</BasicButton>
        </div>
      </form>
    </FormWrapper>
  )
}
