import React from "react"
import TransitionLink from "gatsby-plugin-transition-link"
import styled from "styled-components"
import Flex from "styled-flex-component"
import {
  PrismicImage,
  BodyCopy,
  FullSliceContainer,
  StandardContainer,
  HeadingThree,
  CircleButtonLight,
} from "../../common"

const StyledFullSliceContainer = styled(FullSliceContainer)`
  position: relative;
  /* padding: 2rem; */
  button {
    margin-top: 2rem;
  }
  display: grid;
  grid-template-rows: 3rem auto 3rem;
  grid-template-columns: 1.5rem auto 1.5rem;

  @media ${props => props.theme.breakpoints.medium} {
    grid-template-rows: 4rem auto 4rem;
    grid-template-columns: 3rem auto 3rem;
  }

  @media ${props => props.theme.breakpoints.large} {
    grid-template-rows: 8rem auto 4rem;
    grid-template-columns: 1fr 1fr;
  }
`

const StyledTextBannerInner = styled(StandardContainer)`
  background-color: ${props => props.theme.colors.pullColor};
  grid-row: 2;
  grid-column: 2;
  > ${Flex} {
    color: #fff;
    z-index: 10;
  }
  @media ${props => props.theme.breakpoints.large} {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-row: 2 / span 2;
    grid-column: 1 / span 2;
    background-color: transparent;
    > ${Flex} {
      grid-column: 1;
      padding: 5rem 3rem;
      border-radius: 0.3rem;
      background-color: ${props => props.theme.colors.pullColor};
    }
  }
`
const StyledHeadingThree = styled(HeadingThree)`
  margin-top: 1rem !important;
`

const StyledImageWrapper = styled.div`
  grid-row: 1 / span 3;
  grid-column: 1 / span 3;
  position: relative;
  > .gatsby-image-wrapper,
  img {
    display: block;
    max-height: 800px;
    object-fit: cover;
    position: absolute !important;
    top: 0%;
    left: 0;
    bottom: 0%;
    right: 0;
    z-index: -10;
  }
  @media ${props => props.theme.breakpoints.large} {
    grid-row: 1 / span 2;
  }
`

export const TextBackgroundImage = ({
  title1,
  image,
  text,
  link_text,
  link,
}) => {
  return (
    <StyledFullSliceContainer nopad>
      <StyledImageWrapper>
        <PrismicImage {...image} />
      </StyledImageWrapper>
      <StyledTextBannerInner pad-tb nopad-tb-l>
        <Flex alignCenter>
          <BodyCopy>
            <StyledHeadingThree>{title1.text}</StyledHeadingThree>
            <div dangerouslySetInnerHTML={{ __html: text.html }} />
            {link && (
              <TransitionLink
                to={link.url}
                exit={{
                  length: 0.3,
                }}
                entry={{
                  length: 0.3,
                  delay: 0.3,
                }}
              >
                {link_text && <CircleButtonLight text={link_text.text} />}
              </TransitionLink>
            )}
          </BodyCopy>
        </Flex>
      </StyledTextBannerInner>
    </StyledFullSliceContainer>
  )
}
