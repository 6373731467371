import React from "react"
import styled from "styled-components"

import { BodyCopy, StandardContainer, FullSliceContainer } from "../../common"

import { PageNav } from "../"

const StyledFulSliceContainer = styled(FullSliceContainer)`
  padding: 0 !important;
  @media ${props => props.theme.breakpoints.large} {
    ${BodyCopy} {
      width: calc((100% / 12) * 7);
    }
  }
  @media ${props => props.theme.breakpoints.medium} {
    ${BodyCopy} {
      width: 65%;
    }
  }
`
const StyledStandardContainer = styled(StandardContainer)`
  @media ${props => props.theme.breakpoints.medium} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`

const PageLinks = styled.div`
  margin: 2rem 0;
  @media ${props => props.theme.breakpoints.medium} {
    width: 25%;
    margin: 0;
  }
  @media ${props => props.theme.breakpoints.large} {
    width: calc((100% / 12) * 3);
  }
`

export const PageIntro = ({ text, show_page_nav, items, id }) => {
  return (
    <StyledFulSliceContainer id={"PageIntro"}>
      <StyledStandardContainer>
        <BodyCopy dangerouslySetInnerHTML={{ __html: text.html }} />
        {show_page_nav === "yes" && (
          <PageLinks>
            <PageNav items={items} />
          </PageLinks>
        )}
      </StyledStandardContainer>
    </StyledFulSliceContainer>
  )
}
