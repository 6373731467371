import React from "react"

import { useTransitionState } from "gatsby-plugin-transition-link/hooks"
import { useSpring, animated } from "react-spring"

export const BasicPageTransition = ({ children }) => {
  const { current, mount } = useTransitionState()

  const props = useSpring({
    opacity: mount ? 1 : 0,
    config: { duration: current.length * 1000 },
  })

  return <animated.div style={props}>{children}</animated.div>
}
