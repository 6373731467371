import React from "react"
import styled, { css } from "styled-components"

import {
  BodyCopy,
  FullSliceContainer,
  PrismicImage,
  HeadingThree,
} from "../../common"

const BodyCopyWrapper = styled.div`
  padding: 2rem ${props => props.theme.padding.hMobile};
`

const ImageWrapper = styled.div``

const StyledFullSliceContainer = styled(FullSliceContainer)`
  @media ${props => props.theme.breakpoints.large} {
    display: flex;
    align-items: center;
    /* max-width: ${props => props.theme.containerWidths.wide};
    margin-left: auto;
    margin-right: auto; */
    img {
      width: 100%;
    }
    > ${ImageWrapper} {
      width: 50%;
    }
    > ${BodyCopyWrapper} {
        width: 50%;
        padding: 2rem 4rem;
        max-width: ${props => props.theme.measures.standard};
        margin-left: auto;
        margin-right: auto;      
    }
    ${props =>
      props.reversed &&
      css`
        flex-direction: row-reverse;
      `}    
  }
`

export const ImageText = ({ slice_title, text, image, reverse_layout, id }) => {
  const isReversed = reverse_layout === "yes"
  return (
    <StyledFullSliceContainer nopad reversed={isReversed} id={id}>
      <ImageWrapper>
        <PrismicImage {...image} />
      </ImageWrapper>
      <BodyCopyWrapper>
        <HeadingThree>{slice_title.text}</HeadingThree>
        <BodyCopy dangerouslySetInnerHTML={{ __html: text.html }} />
      </BodyCopyWrapper>
    </StyledFullSliceContainer>
  )
}
