import React from "react"
import styled from "styled-components"
import Flex from "styled-flex-component"
import { useCountUp } from "react-countup"
import VisibilitySensor from "react-visibility-sensor"

import { StandardContainer, FullSliceContainer } from "../../common"

const StyledFullSliceContainer = styled(FullSliceContainer)``

const StyledStandardContainer = styled(StandardContainer)`
  .counter,
  span,
  h4,
  h5,
  h6 {
    font-family: ${props => props.theme.fonts.medium};
  }
  h4 {
    display: inline-block;
  }

  h5 {
    font-size: 20px;
    display: inline-block;
    margin-left: 10px;
  }
`

// Stat
const Stat = ({
  statistic_title,
  start_value,
  end_value,
  value_decimals,
  units,
  value_suffix,
}) => {
  const { countUp, start } = useCountUp({
    start: start_value.text,
    end: end_value.text,
    decimals: value_decimals,
    decimal: ".",
    delay: 0,
    duration: 2.75,
  })
  return (
    <VisibilitySensor
      onChange={isVisible => {
        if (isVisible) {
          start()
        }
      }}
    >
      <div class="counter">
        <h6>{statistic_title.text}</h6>
        <h4>{countUp}</h4>
        <h4>{value_suffix.text}</h4>
        <h5>{units.text}</h5>
      </div>
    </VisibilitySensor>
  )
}

export const Statistics = ({ items }) => {
  return (
    <StyledFullSliceContainer>
      <StyledStandardContainer>
        <Flex wrap justifyBetween>
          {items.map(item => (
            <Stat {...item} />
          ))}
        </Flex>
      </StyledStandardContainer>
    </StyledFullSliceContainer>
  )
}
