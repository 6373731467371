import React from "react"
import styled from "styled-components"
import AnchorLink from "react-anchor-link-smooth-scroll"

const StyledAnchorLink = styled(AnchorLink)`
  display: list-item;
  list-style-type: none;
  text-decoration: underline;
  margin-top: 1rem;
  color: ${props => props.theme.colors.pullColor};
`
export const PageNav = ({ items }) => {
  const PageNavItems = items.filter(function(item) {
    if (item.slice_type !== "page_nav" && item.primary) {
      return item.primary.include_in_page_nav === "yes"
    }
    return null
  })

  // const itemIds = items.map(a => a.id)
  return (
    <>
      <strong>Jump to links</strong>
      {PageNavItems.map(menuItem => (
        <StyledAnchorLink href={`#${menuItem.id}`} offset="0">
          {menuItem.primary.page_nav_title.text}
        </StyledAnchorLink>
      ))}
    </>
  )
}
