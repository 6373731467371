import React from "react"
import TransitionLink from "gatsby-plugin-transition-link"
import styled from "styled-components"

import {
  BodyCopy,
  StandardContainer,
  FullSliceContainer,
  BasicButton,
} from "../../common"

const StyledText = styled(FullSliceContainer)`
  ${StandardContainer} {
    text-align: center;
  }
  * {
    text-decoration: none;
  }
`

export const EnquiryPrompt = ({ text, link, link_text }) => {
  return (
    <StyledText>
      <StandardContainer narrow>
        <BodyCopy centered dangerouslySetInnerHTML={{ __html: text.html }} />
        {link && (
          <TransitionLink
            to={link.url}
            exit={{
              length: 0.3,
            }}
            entry={{
              length: 0.3,
              delay: 0.3,
            }}
          >
            <BasicButton>{link_text.text}</BasicButton>
          </TransitionLink>
        )}
      </StandardContainer>
    </StyledText>
  )
}
