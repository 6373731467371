import React from "react"
import TransitionLink from "gatsby-plugin-transition-link"
import styled from "styled-components"

import {
  BodyCopy,
  StandardContainer,
  FullSliceContainer,
  HeadingThree,
  CircleButtonDark,
} from "../../common"

const StyledTwoColText = styled(FullSliceContainer)`
  @media ${props => props.theme.breakpoints.medium} {
    ${BodyCopy} {
      /* column-count: 2;
      column-gap: 4rem; */
      > p {
        margin-top: 0;
      }
    }
    * {
      text-decoration: none;
    }
    button {
      margin-top: 1rem;
    }
  }
`

export const SingleColumnText = ({
  slice_title,
  text,
  link,
  link_title,
  id,
}) => {
  return (
    <StyledTwoColText id={id}>
      <StandardContainer>
        <HeadingThree>{slice_title.text}</HeadingThree>
        <BodyCopy dangerouslySetInnerHTML={{ __html: text.html }} />
        {link.url && (
          <TransitionLink
            to={link.url}
            exit={{
              length: 0.3,
            }}
            entry={{
              length: 0.3,
              delay: 0.3,
            }}
          >
            <CircleButtonDark text={link_title.text} />
          </TransitionLink>
        )}
      </StandardContainer>
    </StyledTwoColText>
  )
}
