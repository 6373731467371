import React from "react"
import styled from "styled-components"
import Flex from "styled-flex-component"
import CountUp from "react-countup"
import BackgroundImage from "gatsby-background-image"
// import AnchorLink from "react-anchor-link-smooth-scroll"
import Scroll from "react-scroll"
import useMeasure from "react-use-measure"
import { ResizeObserver } from "@juggle/resize-observer"

import { StandardContainer, CircleButtonDownLight } from "../../common"
// import { TransitionLink } from "gatsby-plugin-transition-link/components/TransitionLink"

const StyledFeatureBanner = styled(Flex)`
  position: relative;
  min-height: 100vh;
  flex-wrap: wrap;
  color: #fff;
  display: flex;
  align-items: center;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background: rgba(0, 42, 41, 0.56);
    display: block;
    z-index: 2;
  }
  > .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute !important;
    left: 0;
    top: 0;
    z-index: 0;
  }
`
const BannerContent = styled(StandardContainer)`
  padding-top: 2rem;
  width: 100%;

  grid-row: 2 / span 1;
  button {
    z-index: 10;
  }
  * {
    animation-name: fadeInDown;
    animation-duration: 1s;
    animation-fill-mode: backwards;
    &:nth-child(2) {
      animation-delay: 0.3s;
    }
    &:nth-child(3) {
      animation-delay: 0.45s;
    }
    &:nth-child(4) {
      animation-delay: 0.45s;
    }
  }
  @media ${props => props.theme.breakpoints.large} {
  }
`

const StyledBannerText = styled.div`
  position: relative;
  z-index: 10;
  width: 100%;
  margin-bottom: 1rem;
  h1 {
    max-width: 15em;
    margin-bottom: 1rem;
    line-height: ${props => props.theme.lineHeights.heading};
    > em {
      font-family: ${props => props.theme.fonts.medium};
      font-style: normal;
      color: ${props => props.theme.colors.pullColor};
    }
  }
  > p {
    max-width: ${props => props.theme.measures.standard};
    line-height: ${props => props.theme.lineHeights.standard};
  }
`

const BannerStatistics = styled(StandardContainer)`
  display: none;
  margin: 2rem 0;
  z-index: 10;
  .counter,
  span,
  h4,
  h5,
  h6 {
    font-family: ${props => props.theme.fonts.medium};
  }
  h4 {
    display: inline-block;
  }

  h5 {
    font-size: 20px;
    display: inline-block;
    margin-left: 10px;
  }

  h6 {
    margin-bottom: 0;
  }

  .counter {
    width: 50%;
  }

  @media ${props => props.theme.breakpoints.medium} {
    display: block;
    margin: 0 auto;
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    display: flex;
    justify-content: space-between;
    .counter {
      width: unset;
    }
  }
`
// Stat
const BannerStat = ({
  statistic_start_value,
  statistic_end_value,
  statistic_decimals,
  statistic_factor,
  statistic_title,
  statistic_units,
}) => {
  return (
    <>
      <CountUp
        start={statistic_start_value.text}
        end={statistic_end_value.text}
        duration={2.75}
        decimals={statistic_decimals}
        decimal="."
        delay={0}
      >
        {({ countUpRef }) => (
          <div className="counter">
            <h6>{statistic_title.text}</h6>
            <h4>
              <span ref={countUpRef} />
            </h4>
            <h4>{statistic_factor.text}</h4>
            <h5>{statistic_units.text}</h5>
          </div>
        )}
      </CountUp>
    </>
  )
}

const StyledFeatureBannerFallback = styled.div`
  background-image: url(${props => props.src});
  background-size: cover;
  width: 100%;
  height: 300px;
  display: block;
  @media ${props => props.theme.breakpoints.medium} {
    position: absolute !important;
    height: 100%;
    top: 0;
    left: 0;
  }
`

const FeatureBannerImage = ({ thumbnails, localFile, url, ...props }) => {
  const sources = localFile
    ? [
        {
          ...thumbnails.mobile.localFile.childImageSharp.fluid,
          media: `(max-width: 768px)`,
        },
        {
          ...localFile.childImageSharp.fluid,
          media: `(min-width: 768px)`,
        },
      ]
    : null
  return (
    <>
      {sources ? (
        <BackgroundImage {...props} fluid={sources} />
      ) : (
        <StyledFeatureBannerFallback
          src={url}
          {...props}
          className="styled-bg"
        ></StyledFeatureBannerFallback>
      )}
    </>
  )
}

export const FeatureBanner = ({
  image,
  text,
  link_text,
  items,
  id,
  page_reference,
}) => {
  const scroll = Scroll.animateScroll
  const [ref, measure] = useMeasure({ polyfill: ResizeObserver })
  return (
    <StyledFeatureBanner ref={ref} id={id} full alignEnd>
      <FeatureBannerImage {...image} />
      <BannerContent>
        <StyledBannerText dangerouslySetInnerHTML={{ __html: text.html }} />
        <span
          role="button"
          tabIndex={0}
          onKeyDown={() => scroll.scrollTo(measure.height)}
          onClick={() => scroll.scrollTo(measure.height)}
        >
          <CircleButtonDownLight text="Read More" />
        </span>
      </BannerContent>
      <BannerStatistics alignCenter flex wrap>
        {items.map(item => (
          <BannerStat {...item} />
        ))}
      </BannerStatistics>
    </StyledFeatureBanner>
  )
}
