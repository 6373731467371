import React from "react"
import styled from "styled-components"

import { StandardContainer, PrismicImage } from "../../common"

const StyledStandardContainer = styled(StandardContainer)`
  * {
    animation-name: fadeInDown;
    animation-duration: 1s;
    animation-fill-mode: backwards;
  }
`

export const SingleImage = ({ image }) => {
  return (
    <StyledStandardContainer full alignEnd>
      <PrismicImage {...image}></PrismicImage>
    </StyledStandardContainer>
  )
}
