import React from "react"

import {
  FeatureBanner,
  StandardBanner,
  TwoColText,
  SingleColumnText,
  TextBackgroundImage,
  EnquiryPrompt,
  InformationGrid,
  SingleImage,
  PageSpacer,
  Statistics,
  ImageText,
  PageIntro,
  CareerForm,
  EnquiryForm,
} from "./"

export const SliceZone = ({ slices }) => {
  const slice = slices.map(s => {
    switch (s.slice_type) {
      case "spacer":
        return <PageSpacer {...s.primary} key={s.id} />
      case "page_intro":
        return <PageIntro {...s.primary} id={s.id} key={s.id} items={slices} />
      case "text___backgorund_image":
        return <TextBackgroundImage {...s.primary} key={s.id} />
      case "feature_banner":
        return (
          <FeatureBanner {...s.primary} key={s.id} id={s.id} items={s.items} />
        )
      case "standard_banner":
        return <StandardBanner {...s.primary} key={s.id} />
      case "enquiry_prompt":
        return <EnquiryPrompt {...s.primary} key={s.id} />
      case "information_grid":
        return (
          <InformationGrid
            {...s.primary}
            id={s.id}
            key={s.id}
            items={s.items}
          />
        )
      case "image":
        return <SingleImage {...s.primary} key={s.id} />
      case "single_column_text":
        return <SingleColumnText {...s.primary} id={s.id} key={s.id} />
      case "two_column_text":
        return <TwoColText {...s.primary} id={s.id} key={s.id} />
      case "statistics":
        return <Statistics {...s.primary} key={s.id} items={s.items} />
      case "image___text":
        return <ImageText {...s.primary} id={s.id} key={s.id} />
      case "careers_form":
        return <CareerForm {...s.primary} id={s.id} key={s.id} />
      case "enquiry_form":
        return <EnquiryForm {...s.primary} id={s.id} key={s.id} />
      default:
        return null
    }
  })
  return <div className="slice-zone">{slice}</div>
}
